import React, { MouseEvent, useCallback } from 'react';
import { useFormik } from 'formik';

import styles from './DeleteRespondentForm.module.scss';
import Button from '../Button/Button';
import TextInput from '../TextInput/TextInput';

type Props = {
  onCancel: () => void;
  onDeleteRespondent: (respondent: string) => Promise<void>;
};

type Values = {
  respondent: string;
};

export default function DeleteRespondentForm({
  onCancel,
  onDeleteRespondent,
}: Props) {
  const { handleSubmit, ...formik } = useFormik<Values>({
    initialValues: {
      respondent: '',
    },
    onSubmit: async ({ respondent }, { resetForm }) => {
      await onDeleteRespondent(respondent);
      resetForm();
    },
  });

  const onDeleteRespondentClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Delete Respondent</h1>
      </div>
      <div className={styles.content}>
        <TextInput
          id="respondent"
          name="respondent"
          value={formik.values.respondent}
          onChange={formik.handleChange}
          label="Respondent #"
        />
        <Button label="Delete" onClick={onDeleteRespondentClick} />
      </div>
      <div className={styles.footer}>
        <div>
          <Button label="Cancel" onClick={onCancel} isOutlined />
        </div>
      </div>
    </div>
  );
}
