import React from "react";
import cn from 'classnames';
import styles from "./Checkbox.module.scss";

type Props = {
  id: string;
  name?: string;
  label: string;
  checked: boolean;
  onChange: any;
  className?: string;
};

export default function Checkbox({
  label,
  name,
  id,
  checked,
  onChange,
  className,
}: Props) {
  return (
    <div>
      <label className={cn(styles.control, styles.controlCheckbox, className)}>
        {label}
        <input
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <div className={styles.controlIndicator} />
      </label>
    </div>
  );
}
