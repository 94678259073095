import React, { useCallback } from 'react';
import styles from './QC2.module.scss';
import xlsUploadIcon from '../../../assets/images/xls-file.png';
import xlsDownloadIcon from '../../../assets/images/xls-download.png';
import UploadFile from '../../../components/UploadFile/UploadFile';
import DownloadFile from '../../../components/DownloadFile/DownloadFile';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  generateQc2Bma,
  uploadQc2BmaFile,
  uploadQc2BmaFileLoadingSelector,
} from '../../../store/qc2';
import { getIsError422, showCommonError, showToast } from '../../../utils';
import { AxiosError } from 'axios';

const xlsxAccept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

type Props = {
  projectId: string;
};

export default function QC2({ projectId }: Props) {
  const dispatch = useAppDispatch();
  const uploadQc2BmaFileLoading = useAppSelector<boolean>(
    uploadQc2BmaFileLoadingSelector,
  );

  const onDownloadQc2File = useCallback(() => {
    showToast('success', 'Download has started successfully');
    dispatch(generateQc2Bma(projectId));
  }, [dispatch, projectId]);

  const onUploadQc2BmaFile = useCallback(
    async (file: File) => {
      const result = await dispatch(uploadQc2BmaFile({ projectId, file }));
      if (result.type === uploadQc2BmaFile.fulfilled.type) {
        showToast('success', 'File uploaded successfully');
      } else if (
        result.type === uploadQc2BmaFile.rejected.type &&
        !getIsError422(result.payload as AxiosError)
      ) {
        showCommonError();
      }
    },
    [dispatch, projectId],
  );

  return (
    <div className={styles.container}>
      <DownloadFile
        label="Download"
        fileIcon={xlsDownloadIcon}
        onDownload={onDownloadQc2File}
      />
      <UploadFile
        label="Upload"
        fileIcon={xlsUploadIcon}
        accept={xlsxAccept}
        loading={uploadQc2BmaFileLoading}
        onUpload={onUploadQc2BmaFile}
      />
    </div>
  );
}
