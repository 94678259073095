import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Project } from '../types';
import axios from 'axios';
import { RootState } from './';
import { downloadFile, getUrl } from '../utils';

export type ProjectsState = {
  ruleLoading: boolean;
  deckLoading: boolean;
  projects: Project[] | null;
  selectedProject: Project | null;
};

const initialState: ProjectsState = {
  ruleLoading: false,
  deckLoading: false,
  projects: null,
  selectedProject: null,
};

type FetchProjectsResponse = {
  projects: Project[];
};
export const fetchAllProjects = createAsyncThunk<Project[]>(
  'projects/all',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<FetchProjectsResponse>(
        getUrl('/test/projects/all'),
      );
      return response.data.projects;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export type CreateProjectPayload = {
  project_name: string;
  company_name: string;
  brand_name: string;
  project_size: number;
  using_bma: boolean;
  survey_id: string | null;
};
export const createProject = createAsyncThunk<Project, CreateProjectPayload>(
  'projects/create',
  async (body, thunkAPI) => {
    try {
      const response = await axios.post<Project>(
        getUrl('/test/projects/'),
        body,
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export type ProjectRulePayload = {
  projectId: string;
  rule: File;
};
export const uploadProjectRule = createAsyncThunk<string, ProjectRulePayload>(
  'projects/project/rule',
  async ({ projectId, rule }, thunkAPI) => {
    try {
      const body = new FormData();
      body.append('file', rule);
      const response = await axios.post(
        getUrl(`/test/projects/${projectId}/rule`),
        body,
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export type ProjectDeckPayload = {
  projectId: string;
  deck: File;
};

export const uploadProjectDeck = createAsyncThunk<string, ProjectDeckPayload>(
  'projects/project/deck',
  async ({ projectId, deck }, thunkAPI) => {
    try {
      const body = new FormData();
      body.append('file', deck);
      const response = await axios.post(
        getUrl(`/test/projects/${projectId}/deck`),
        body,
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchProjectById = createAsyncThunk<Project, string>(
  'projects/getById',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(getUrl(`/test/projects/${id}`));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const updateProject = createAsyncThunk<Project, Project>(
  'project/update',
  async (project, thunkAPI) => {
    try {
      const response = await axios.patch(
        getUrl(`/test/projects/${project.id}`),
        project,
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const downloadProjectCohorts = createAsyncThunk(
  'project/cohorts/download',
  async (projectId: string, thunkAPI) => {
    try {
      const response = await axios.get(
        getUrl(`/test/projects/${projectId}/cohorts`),
        { responseType: 'blob' },
      );
      downloadFile(response.data, response.headers.file_name);
      return response.headers.file_name;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetSelectedProject: (state: ProjectsState) => {
      state.selectedProject = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllProjects.fulfilled,
      (state: ProjectsState, action: PayloadAction<Project[]>) => {
        state.projects = action.payload;
      },
    );

    builder.addCase(
      createProject.fulfilled,
      (state: ProjectsState, action: PayloadAction<Project>) => {
        if (!state.projects) {
          state.projects = [];
        }
        state.projects.push(action.payload);
      },
    );

    builder.addCase(
      fetchProjectById.fulfilled,
      (state: ProjectsState, action: PayloadAction<Project>) => {
        state.selectedProject = action.payload;
      },
    );

    builder.addCase(
      updateProject.fulfilled,
      (state: ProjectsState, action: PayloadAction<Project>) => {
        state.selectedProject = action.payload;
        state.projects = (state.projects || []).map((project) => {
          return project.id === action.payload.id ? action.payload : project;
        });
      },
    );

    builder.addCase(uploadProjectRule.pending, (state: ProjectsState) => {
      state.ruleLoading = true;
    });

    builder.addCase(uploadProjectDeck.pending, (state: ProjectsState) => {
      state.deckLoading = true;
    });

    builder.addMatcher(
      (action) => {
        return (
          action.type === uploadProjectRule.fulfilled.type ||
          action.type === uploadProjectRule.rejected.type
        );
      },
      (state: ProjectsState) => {
        state.ruleLoading = false;
      },
    );

    builder.addMatcher(
      (action) => {
        return (
          action.type === uploadProjectDeck.fulfilled.type ||
          action.type === uploadProjectDeck.rejected.type
        );
      },
      (state: ProjectsState) => {
        state.deckLoading = false;
      },
    );
  },
});

const projectsStateSelector = (state: RootState) => state.projectsReducer;
export const projectsSelector = createSelector(
  projectsStateSelector,
  (projectsState) => projectsState.projects,
);

export const selectedProjectSelector = createSelector(
  projectsStateSelector,
  (projectsState) => projectsState.selectedProject,
);

export const ruleLoadingSelector = createSelector(
  projectsStateSelector,
  (projectsState) => projectsState.ruleLoading,
);

export const deckLoadingSelector = createSelector(
  projectsStateSelector,
  (projectsState) => projectsState.deckLoading,
);
