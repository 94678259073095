import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { downloadFile, getUrl } from '../utils';
import { RootState } from './index';
import { Cohort } from '../types';

export const generateQc2Bma = createAsyncThunk<Blob, string>(
  'qc2/bma/generateFile',
  async (projectId, thunkAPI) => {
    try {
      const response = await axios.get(
        getUrl(`/test/projects/${projectId}/qc2/bma/generate`),
        { responseType: 'blob' },
      );
      downloadFile(response.data, response.headers.file_name);
      return response.headers.file_name;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

type UploadQc2BmaFilePayload = {
  file: File;
  projectId: string;
};
export const uploadQc2BmaFile = createAsyncThunk<
  string,
  UploadQc2BmaFilePayload
>('qc2/bma/uploadFile', async ({ file, projectId }, thunkAPI) => {
  try {
    const body = new FormData();
    body.append('file', file);
    const response = await axios.post(
      getUrl(`/test/projects/${projectId}/qc2/bma/upload`),
      body,
    );
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getQc2BmaConsolidation = createAsyncThunk<Cohort[], string>(
  'project/qc2/consolidation',
  async (projectId, thunkAPI) => {
    try {
      const response = await axios.get(
        getUrl(`/test/projects/${projectId}/qc2/consolidation`),
      );
      return response.data?.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

type UploadQc2ConsolidationFilePayload = {
  file: File;
  projectId: string;
};
export const uploadQc2ConsolidationFile = createAsyncThunk<
  string,
  UploadQc2ConsolidationFilePayload
>(
  'project/qc2/consolidation/uploadFile',
  async ({ file, projectId }, thunkAPI) => {
    try {
      const body = new FormData();
      body.append('file', file);
      const response = await axios.post(
        getUrl(`/test/projects/${projectId}/qc2/consolidation/upload`),
        body,
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

type DownloadQc2ConsolidationFilePayload = {
  projectId: string;
  consolidation_sections: [string, string, string][];
};
export const downloadQc2ConsolidationFile = createAsyncThunk<
  string,
  DownloadQc2ConsolidationFilePayload
>(
  'project/qc2/consolidation/generate',
  async ({ projectId, ...body }, thunkAPI) => {
    try {
      const response = await axios.post(
        getUrl(`/test/projects/${projectId}/qc2/consolidation/generate`),
        body,
        { responseType: 'blob' },
      );
      downloadFile(response.data, response.headers.file_name);
      return response.headers.file_name;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export type Qc2State = {
  uploadQc2BmaFileLoading: boolean;
  uploadQc2ConsolidationFileLoading: boolean;
  cohorts: Cohort[];
};

const initialState: Qc2State = {
  uploadQc2BmaFileLoading: false,
  uploadQc2ConsolidationFileLoading: false,
  cohorts: [],
};

export const qc2Slice = createSlice({
  name: 'qc2',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadQc2BmaFile.pending, (state: Qc2State) => {
      state.uploadQc2BmaFileLoading = true;
    });

    builder.addCase(uploadQc2ConsolidationFile.pending, (state: Qc2State) => {
      state.uploadQc2ConsolidationFileLoading = true;
    });

    builder.addCase(
      getQc2BmaConsolidation.fulfilled,
      (state: Qc2State, action: PayloadAction<Cohort[]>) => {
        state.cohorts = action.payload;
      },
    );

    builder.addMatcher(
      (action) => {
        return (
          action.type === uploadQc2BmaFile.fulfilled.type ||
          action.type === uploadQc2BmaFile.rejected.type
        );
      },
      (state: Qc2State) => {
        state.uploadQc2BmaFileLoading = false;
      },
    );

    builder.addMatcher(
      (action) => {
        return (
          action.type === uploadQc2ConsolidationFile.fulfilled.type ||
          action.type === uploadQc2ConsolidationFile.rejected.type
        );
      },
      (state: Qc2State) => {
        state.uploadQc2ConsolidationFileLoading = false;
      },
    );
  },
});

export const qc2StateSelector = (state: RootState) => state.qc2Reducer;

export const uploadQc2BmaFileLoadingSelector = createSelector(
  qc2StateSelector,
  (state: Qc2State) => state.uploadQc2BmaFileLoading,
);

export const uploadQc2ConsolidationFileLoadingSelector = createSelector(
  qc2StateSelector,
  (state: Qc2State) => state.uploadQc2ConsolidationFileLoading,
);

export const cohortsSelector = createSelector(
  qc2StateSelector,
  (state: Qc2State) => state.cohorts,
);
