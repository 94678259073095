import React, { useCallback } from 'react';
import styles from './QC3.module.scss';
import xlsUploadIcon from '../../../assets/images/xls-file.png';
import xlsDownloadIcon from '../../../assets/images/xls-download.png';
import UploadFile from '../../../components/UploadFile/UploadFile';
import DownloadFile from '../../../components/DownloadFile/DownloadFile';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  uploadQc3File,
  fetchQc3Consolidation,
  uploadQc3FileLoadingSelector,
} from '../../../store/qc3';
import { getIsError422, showCommonError, showToast } from '../../../utils';
import { AxiosError } from 'axios';

const xlsxAccept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

type Props = {
  projectId: string;
};

export default function QC3({ projectId }: Props) {
  const dispatch = useAppDispatch();
  const uploadQc3FileLoading = useAppSelector<boolean>(
    uploadQc3FileLoadingSelector,
  );

  const onDownloadQc3File = useCallback(async () => {
    showToast('success', 'Download has started successfully');
    dispatch(fetchQc3Consolidation(projectId));
  }, [dispatch, projectId]);

  const onUploadQc3File = useCallback(
    async (file: File) => {
      const result = await dispatch(uploadQc3File({ projectId, file }));
      if (result.type === uploadQc3File.fulfilled.type) {
        showToast('success', 'File uploaded successfully');
      } else if (
        result.type === uploadQc3File.rejected.type &&
        !getIsError422(result.payload as AxiosError)
      ) {
        showCommonError();
      }
    },
    [dispatch, projectId],
  );

  return (
    <div className={styles.container}>
      <DownloadFile
        label="Download"
        fileIcon={xlsDownloadIcon}
        onDownload={onDownloadQc3File}
      />
      <UploadFile
        label="Upload"
        fileIcon={xlsUploadIcon}
        accept={xlsxAccept}
        loading={uploadQc3FileLoading}
        onUpload={onUploadQc3File}
      />
    </div>
  );
}
