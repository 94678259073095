import React from 'react';

import styles from './Radio.module.scss';

type Props = {
  id: string;
  name: string;
  value: string;
  label: string;
  checked: boolean;
  onChange: () => void;
};
export default function Radio({
  id,
  name,
  value,
  label,
  checked,
  onChange,
}: Props) {
  return (
    <div className={styles.container}>
      <input
        id={id}
        name={name}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}
