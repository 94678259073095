import React, { useEffect, useImperativeHandle, useState } from 'react';
import { CheckboxTree, Node } from './TreeNode';
import { Cohort } from '../../types';

type Props = {
  cohort: Cohort;
};

function createValues(cohort: Cohort, parentValue = '') {
  const newValue = [parentValue, cohort.label]
    .filter((item) => !!item)
    .join(';');
  const updatedCohort = {
    label: cohort.label,
    value: newValue,
    children: cohort.children?.length ? [] : undefined,
  };

  for (const child of cohort?.children || []) {
    if (updatedCohort.children) {
      // @ts-ignore
      updatedCohort.children.push(createValues(child, newValue));
    }
  }
  return updatedCohort as Node;
}

const CohortsSelector = React.forwardRef<{ getChecked: () => string[] }, Props>(
  ({ cohort }, ref) => {
    const [rootNode, setRootNode] = useState<Node | null>(null);
    const [checked, setChecked] = useState<string[]>([]);
    const [expended, setExpanded] = useState<string[]>([]);
    useImperativeHandle(ref, () => ({ getChecked: () => checked }), [checked]);

    useEffect(() => {
      setRootNode(createValues(cohort));
    }, [cohort]);

    return rootNode ? (
      <CheckboxTree
        rootNode={rootNode}
        checked={checked}
        expended={expended}
        onCheck={setChecked}
        onExpand={setExpanded}
      />
    ) : null;
  },
);

export default CohortsSelector;
