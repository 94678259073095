import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUrl } from '../utils';

export const trainingSentiment = createAsyncThunk<string, void>(
  'training/sentiment',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(getUrl('/test/training/sentiment'), {});
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const trainingTopic = createAsyncThunk<string, void>(
  'training/topic',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(getUrl('/test/training/topic'), {});
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const trainingWhisper = createAsyncThunk<string, void>(
  'training/whisper',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(getUrl('/test/training/whisper'), {});
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const trainingConsolidation = createAsyncThunk<string, void>(
  'training/consolidation',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post('/test/training/consolidation', {});
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
