export enum SurveyStatus {
  None = 'none',
}

export enum ProjectStatus {
  Rules = 'Rules',
  QC1 = 'QC1',
  BmaTraining = 'BMA Training',
  QC2 = 'QC2 BMA',
  ClusterTraining = 'Cluster Training',
  QC3 = 'QC3 Cluster',
  SecondPages = '2nd pages',
  DataRoom = 'Data Room',
}

export type Survey = {
  created_at: string;
  id: string;
  name: string;
  status: SurveyStatus;
};

export type User = {
  username: string;
};

export type BrandMessage = {
  message: string;
  short_message: string;
};

export type Project = {
  id: string;
  project_name: string;
  company_name: string;
  brand_name: string;
  project_size: number;
  project_status: ProjectStatus;
  using_bma: boolean;
  brand_messages: BrandMessage[] | null;
  survey_id: string | null;
  created_at: string;
  completed: boolean;
};

export type Cohort = {
  id?: string;
  label: string;
  children?: Cohort[];
};
