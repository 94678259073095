import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { downloadFile, getUrl } from '../utils';
import { RootState } from './index';

export const fetchSecondPagesConsolidation = createAsyncThunk(
  'project/secondPages/generate',
  async (projectId: string, thunkAPI) => {
    try {
      const response = await axios.get(
        getUrl(`/test/projects/${projectId}/second_pages/generate`),
        { responseType: 'blob' },
      );
      downloadFile(response.data, response.headers.file_name);
      return response.headers.file_name;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

type UploadSecondPagesFilePayload = {
  file: File;
  projectId: string;
};
export const uploadSecondPagesFile = createAsyncThunk<
  string,
  UploadSecondPagesFilePayload
>('project/secondPages/upload', async ({ file, projectId }, thunkAPI) => {
  try {
    const body = new FormData();
    body.append('file', file);
    const response = await axios.post(
      getUrl(`/test/projects/${projectId}/second_pages/upload`),
      body,
    );
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export type SecondPagesState = {
  uploadSecondPagesFileLoading: boolean;
};

const initialState: SecondPagesState = {
  uploadSecondPagesFileLoading: false,
};

export const secondPagesSlice = createSlice({
  name: 'secondPages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      uploadSecondPagesFile.pending,
      (state: SecondPagesState) => {
        state.uploadSecondPagesFileLoading = true;
      },
    );

    builder.addMatcher(
      (action) => {
        return (
          action.type === uploadSecondPagesFile.fulfilled.type ||
          action.type === uploadSecondPagesFile.rejected.type
        );
      },
      (state: SecondPagesState) => {
        state.uploadSecondPagesFileLoading = false;
      },
    );
  },
});

const secondPagesStateSelector = (state: RootState) => state.secondPagesReducer;

export const uploadSecondPagesFileLoadingSelector = createSelector(
  secondPagesStateSelector,
  (state: SecondPagesState) => state.uploadSecondPagesFileLoading,
);
