import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { tokenSelector } from "../../store/auth";

type Props = {
  redirectPath?: string;
};
export default function ProtectedRoute({ redirectPath = '/sign-in' }: Props) {
  const token = useAppSelector(tokenSelector);

  if (!token && redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
}
