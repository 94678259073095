import React, { useCallback, useEffect, useState } from 'react';

import styles from './QC1.module.scss';
import reloadIcon from '../../../assets/images/reload.png';
import Button from '../../../components/Button/Button';
import UploadFile from '../../../components/UploadFile/UploadFile';
import xlsIcon from '../../../assets/images/xls-file.png';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  downloadQc1File,
  fetchProjectQc1,
  generateProjectQc1Transcribe,
  qcedCountSelector,
  respondentsCountSelector,
  uploadProjectQc1File,
  uploadQc1FileLoadingSelector,
} from '../../../store/qc1';
import {
  downloadProjectCohorts,
  selectedProjectSelector,
} from '../../../store/projects';
import { getIsError422, showCommonError, showToast } from '../../../utils';
import Radio from '../../../components/Radio/Radio';
// import {
//   trainingSentiment,
//   trainingTopic,
//   trainingWhisper,
// } from '../../../store/training';
import { syncPhonicResponses } from '../../../store/surveys';
import { AxiosError } from 'axios';

const xlsxAccept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

type Props = {
  projectId: string;
  onDeleteRespondentClick: () => void;
};

export default function QC1({ projectId, onDeleteRespondentClick }: Props) {
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectedProjectSelector);
  const qcedCount = useAppSelector(qcedCountSelector);
  const respondentsCount = useAppSelector(respondentsCountSelector);
  const uploadFileLoading = useAppSelector(uploadQc1FileLoadingSelector);
  const [settings, setSettings] = useState<string>('');
  useEffect(() => {
    dispatch(fetchProjectQc1(projectId));
  }, [dispatch, projectId]);

  const onDownloadCohortsClick = useCallback(() => {
    dispatch(downloadProjectCohorts(projectId));
  }, [dispatch, projectId]);

  const onDownloadClick = useCallback(() => {
    dispatch(downloadQc1File(projectId));
  }, [dispatch, projectId]);

  const onGenerateTranscribe = useCallback(async () => {
    if (settings) {
      const new_tagged = settings === 'new_tagged';
      const result = await dispatch(
        generateProjectQc1Transcribe({ project_id: projectId, new_tagged }),
      );
      if (result.type === generateProjectQc1Transcribe.fulfilled.type) {
        showToast('success', 'QC file generation has started successfully');
      } else if (result.type === generateProjectQc1Transcribe.rejected.type) {
        showCommonError();
      }
    }
  }, [dispatch, projectId, settings]);

  const onUploadFile = useCallback(
    async (file: File) => {
      const result = await dispatch(uploadProjectQc1File({ projectId, file }));
      if (result.type === uploadProjectQc1File.fulfilled.type) {
        showToast('success', 'QC file uploaded successfully');
        if (projectId) {
          dispatch(fetchProjectQc1(projectId));
        }
      } else if (
        result.type === uploadProjectQc1File.rejected.type &&
        !getIsError422(result.payload as AxiosError)
      ) {
        showCommonError();
      }
    },
    [dispatch, projectId],
  );

  const onTrainTopics = useCallback(async () => {
    // const result = await dispatch(trainingTopic());
    // if (result.type === trainingTopic.fulfilled.type) {
    //   showToast('success', 'Training process has started successfully');
    // } else if (result.type === trainingTopic.rejected.type) {
    //   showCommonError();
    // }
  }, []);

  const onTrainSentiments = useCallback(async () => {
    // const result = await dispatch(trainingSentiment());
    // if (result.type === trainingSentiment.fulfilled.type) {
    //   showToast('success', 'Training process has started successfully');
    // } else if (result.type === trainingSentiment.rejected.type) {
    //   showCommonError();
    // }
  }, []);

  const onTrainASR = useCallback(async () => {
    // const result = await dispatch(trainingWhisper());
    // if (result.type === trainingWhisper.fulfilled.type) {
    //   showToast('success', 'Training process has started successfully');
    // } else if (result.type === trainingWhisper.rejected.type) {
    //   showCommonError();
    // }
  }, []);

  const onSyncPhonicResponses = useCallback(async () => {
    if (project?.survey_id) {
      const result = await dispatch(
        syncPhonicResponses({
          survey_id: project.survey_id,
          projectId,
        }),
      );
      if (result.type === syncPhonicResponses.fulfilled.type) {
        showToast('success', 'Response Sync has started successfully');
        setTimeout(() => {
          dispatch(fetchProjectQc1(project.id));
        }, 5000);
      } else if (result.type === syncPhonicResponses.rejected.type) {
        showCommonError();
      }
    }
  }, [dispatch, project, projectId]);

  return (
    <div className={styles.container}>
      <div className={styles.sideBar}>
        <div className={styles.header}>
          <div>
            <span># of Respondents </span>
            {`${respondentsCount}/${project?.project_size || 0}`}
          </div>
          <button onClick={onSyncPhonicResponses}>
            <img src={reloadIcon} alt="reload icon" />
          </button>
        </div>
        <div className={styles.sideBarContent}>
          <div className={styles.settings}>
            <Radio
              checked={settings === 'new_tagged'}
              onChange={() => setSettings('new_tagged')}
              name="settings"
              id="new_tagged"
              value="new_tagged"
              label="New tagged"
            />
            <Radio
              checked={settings === 'batch_of_5'}
              onChange={() => setSettings('batch_of_5')}
              name="settings"
              id="batch_of_5"
              value="batch_of_5"
              label="Batch of 5"
            />
            <Button
              label="Generate"
              onClick={onGenerateTranscribe}
              isOutlined
            />
            <Button label="Download" onClick={onDownloadClick} />
          </div>
          <div className={styles.actions}>
            <Button
              label="Delete Respondent"
              onClick={onDeleteRespondentClick}
              isOutlined
            />
            <Button label="Download Cohorts" onClick={onDownloadCohortsClick} />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div>
          <div className={styles.contentHeader}>
            <span># of QC'ed </span>
            {`${qcedCount}/${project?.project_size || 0}`}
          </div>
          <div className={styles.uploadContainer}>
            <UploadFile
              fileIcon={xlsIcon}
              label="Upload"
              accept={xlsxAccept}
              loading={uploadFileLoading}
              onUpload={onUploadFile}
            />
          </div>
          <div className={styles.trainButtonsContainer}>
            <Button label="Train Topics" onClick={onTrainTopics} disabled />
            <Button
              label="Train Sentiments"
              onClick={onTrainSentiments}
              disabled
            />
            <Button label="Train ASR" onClick={onTrainASR} disabled />
          </div>
        </div>
      </div>
    </div>
  );
}
