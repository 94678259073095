import React, { useCallback, useMemo, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import styles from './ProjectCard.module.scss';
import { Project } from '../../types';
import Badge from '../Badge/Badge';
import moreIcon from '../../assets/images/more-icon.png';
import clockIcon from '../../assets/images/clock-icon.png';

type Props = {
  project: Project;
  onEditProject: (project: Project) => void;
};

export default function ProjectCard({ project, onEditProject }: Props) {
  const navigate = useNavigate();
  const date = useMemo<string>(
    () => new Date(project.created_at).toLocaleDateString(),
    [project.created_at],
  );
  const openProject = useCallback(() => {
    navigate(`/projects/${project.id}`);
  }, [project, navigate]);

  const editProject = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onEditProject(project);
    },
    [project, onEditProject],
  );

  return (
    <div className={styles.container} onClick={openProject}>
      <div className={styles.header}>
        <Badge isCompleted={project.completed} />
        <DropdownMenu.Root>
          <DropdownMenu.Trigger className={styles.dropdownMenu}>
            <div className={styles.moreIcon}>
              <img src={moreIcon} alt="more icon" />
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content>
              <DropdownMenu.Item
                onClick={editProject}
                className={styles.dropdownMenuItem}>
                Edit Project
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
      <div className={styles.content}>
        <h1>{project.project_name}</h1>
        {!!project.brand_name && <p>{project.brand_name}</p>}
        {!!project.company_name && <p>{project.company_name}</p>}
      </div>
      <div className={styles.footer}>
        <img src={clockIcon} alt="clock icon" />
        <span>{date}</span>
      </div>
    </div>
  );
}
