import React, { MouseEvent, useState, useEffect } from "react";
import { Accept, useDropzone } from "react-dropzone";
import cn from 'classnames';
import MoonLoader from 'react-spinners/MoonLoader';

import Button from "../Button/Button";
import styles from "./UploadFile.module.scss";
import closeIcon from "../../assets/images/red-close-icon.png";

type Props = {
  label: string;
  fileIcon: string;
  accept: Accept;
  loading?: boolean;
  onUpload?: (file: File) => void;
};

export default function UploadFile({
  label,
  fileIcon,
  accept,
  onUpload,
  loading,
}: Props) {
  const [file, setFile] = useState<File | null>(null);
  const { acceptedFiles, inputRef, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      maxFiles: 1,
      accept,
    });

  useEffect(() => {
    if (!!acceptedFiles.length) {
      setFile(acceptedFiles[0]);
    } else {
      setFile(null);
    }
  }, [acceptedFiles]);

  const removeFile = (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    setFile(null);
  };

  const onUploadClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onUpload && file) {
      onUpload(file);
    }
  };

  return !loading ? (
    <div
      {...getRootProps({
        className: cn(styles.container,isDragActive && styles.dragActive),
      })}
    >
      <input {...getInputProps()} ref={inputRef} />
      <div className={styles.content}>
        <img src={fileIcon} alt="file icon" />
        <div>
          <label>{label}</label>
          {!!file && (
            <div>
              <div>{file.name}</div>
              <img onClick={removeFile} src={closeIcon} alt="close icon" />
            </div>
          )}
        </div>
      </div>
      <Button label="Upload" onClick={onUploadClick} />
    </div>
  ) : (
    <div className={cn(styles.container, styles.loading)}>
      <MoonLoader
        color="#BF4C45"
        size={30}
        speedMultiplier={0.5}
      />
      <div className={styles.header}>Upload file...</div>
      <div className={styles.file}>{file?.name}</div>
    </div>
  );
}
