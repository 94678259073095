import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authSlice, authMiddleware } from './auth';
import { projectsSlice } from './projects';
import { qc1Slice } from './qc1';
import { jobsSlice } from './jobs';
import { qc2Slice } from './qc2';
import { qc3Slice } from './qc3';
import { secondPagesSlice } from './secondPages';
import { dataRoomSlice } from './dataRoom';

const rootReducer = combineReducers({
  authReducer: authSlice.reducer,
  projectsReducer: projectsSlice.reducer,
  qc1Reducer: qc1Slice.reducer,
  qc2Reducer: qc2Slice.reducer,
  qc3Reducer: qc3Slice.reducer,
  secondPagesReducer: secondPagesSlice.reducer,
  dataRoomReducer: dataRoomSlice.reducer,
  jobsReducer: jobsSlice.reducer,
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
