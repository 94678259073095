import React, { MouseEvent } from 'react';

import styles from './DownloadFile.module.scss';
import Button from '../Button/Button';

type Props = {
  label: string;
  fileIcon: string;
  onDownload: () => void;
};

export default function DownloadFile({ label, fileIcon, onDownload }: Props) {
  const onDownloadClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDownload();
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={fileIcon} alt="file icon" />
        <div>
          <label>{label}</label>
        </div>
      </div>
      <Button label="Download" onClick={onDownloadClick} />
    </div>
  );
}
