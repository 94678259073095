import axios from 'axios';
import {
  createAsyncThunk,
  createSlice,
  Middleware,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { User } from '../types';
import { RootState } from './';
import { getUrl } from '../utils';

export const TOKEN_STORE_KEY = 'token';

export type AuthState = {
  token: string | null;
  user: User | null;
};

const token = localStorage.getItem(TOKEN_STORE_KEY);
axios.defaults.headers.common['Authorization'] = token;

const initialState: AuthState = {
  token,
  user: null,
};

type LoginPayload = {
  username: string;
  password: string;
};

type LoginError = {
  // TODO update
  rejectValue: any;
};

type LoginResponse = string;
export const login = createAsyncThunk<LoginResponse, LoginPayload, LoginError>(
  'auth/token',
  async ({ username, password }, thunkAPI) => {
    try {
      const body = new URLSearchParams({
        username,
        password,
        grant_type: 'password',
      });
      const response = await axios.post<{
        access_token: string;
        token_type: string;
      }>(getUrl('/auth/token'), body);
      const { token_type, access_token } = response.data;
      return `${token_type} ${access_token}`;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchUser = createAsyncThunk<User>(
  'auth/users/me',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<User>(getUrl('/auth/users/me'));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state: AuthState, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state: AuthState, action) => {
      state.token = action.payload;
    });

    builder.addCase(
      fetchUser.fulfilled,
      (state: AuthState, action: PayloadAction<User>) => {
        state.user = action.payload;
      },
    );
  },
});

export const authMiddleware: Middleware<{}, RootState> =
  () => (next) => (action: PayloadAction<string | null>) => {
    switch (action.type) {
      case login.fulfilled.type:
      case authSlice.actions.setToken.type: {
        const token = action.payload;
        if (token) {
          localStorage.setItem(TOKEN_STORE_KEY, token);
        } else {
          localStorage.removeItem(TOKEN_STORE_KEY);
        }
        axios.defaults.headers.common['Authorization'] = token;
        break;
      }
      default: {
        break;
      }
    }
    return next(action);
  };

const authStateSelector = (state: RootState): AuthState => state.authReducer;
export const tokenSelector = createSelector(
  authStateSelector,
  (authReducer) => authReducer.token,
);
export const userSelector = createSelector(
  authStateSelector,
  (authState) => authState.user,
);
