import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectStatus } from '../types';
import axios from 'axios';
import { getUrl } from '../utils';

export type Job = {
  id: string;
  project_id: string;
  project_name: string;
  project_status: ProjectStatus;
  // TODO check values
  process_status: string;
  process_step: string;
  start_date: string;
  end_date: string;
  error: {
    status_code: 0;
    detail: {
      info: string;
      exception: string;
    };
    headers: string;
  };
};

export type JobsState = {
  jobs: Job[] | null;
  selectedJob: Job | null;
};

const initialState: JobsState = {
  jobs: null,
  selectedJob: null,
};

export const fetchAllJobs = createAsyncThunk<Job[], void>(
  'jobs/all',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(getUrl('/test/jobs/all'));
      return response.data?.jobs;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchJobByProjectId = createAsyncThunk<Job, string>(
  'jobs/projectId',
  async (projectId, thunkApi) => {
    try {
      const response = await axios.get(getUrl(`/test/jobs/${projectId}`));
      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllJobs.fulfilled,
      (state: JobsState, action: PayloadAction<Job[]>) => {
        state.jobs = action.payload;
      },
    );

    builder.addCase(
      fetchJobByProjectId.fulfilled,
      (state: JobsState, action: PayloadAction<Job>) => {
        state.selectedJob = action.payload;
      },
    );
  },
});
