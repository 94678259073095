import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getUrl } from '../utils';

export const syncPhonicResponses = createAsyncThunk<
  string,
  { survey_id: string; projectId: string }
>('survey/sync/phonic', async ({ survey_id, projectId }, thunkAPI) => {
  try {
    const response = await axios.post(
      getUrl(`/test/surveys/${survey_id}/sync/phonic`),
      { projectId },
    );
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
