import React, { useCallback } from 'react';
import styles from './SecondPages.module.scss';
import xlsUploadIcon from '../../../assets/images/xls-file.png';
import xlsDownloadIcon from '../../../assets/images/xls-download.png';
import UploadFile from '../../../components/UploadFile/UploadFile';
import DownloadFile from '../../../components/DownloadFile/DownloadFile';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  uploadSecondPagesFile,
  fetchSecondPagesConsolidation,
  uploadSecondPagesFileLoadingSelector,
} from '../../../store/secondPages';
import { getIsError422, showCommonError, showToast } from '../../../utils';
import { AxiosError } from 'axios';

const xlsxAccept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

type Props = {
  projectId: string;
};

export default function SecondPages({ projectId }: Props) {
  const dispatch = useAppDispatch();
  const uploadSecondPagesFileLoading = useAppSelector<boolean>(
    uploadSecondPagesFileLoadingSelector,
  );

  const onDownloadSecondPagesFile = useCallback(() => {
    showToast('success', '2nd page download has started successfully');
    dispatch(fetchSecondPagesConsolidation(projectId));
  }, [dispatch, projectId]);

  const onUploadSecondPagesFile = useCallback(
    async (file: File) => {
      const result = await dispatch(uploadSecondPagesFile({ projectId, file }));
      if (result.type === uploadSecondPagesFile.fulfilled.type) {
        showToast('success', 'File uploaded successfully');
      } else if (
        result.type === uploadSecondPagesFile.rejected.type &&
        !getIsError422(result.payload as AxiosError)
      ) {
        showCommonError();
      }
    },
    [dispatch, projectId],
  );

  return (
    <div className={styles.container}>
      <DownloadFile
        label="Download"
        fileIcon={xlsDownloadIcon}
        onDownload={onDownloadSecondPagesFile}
      />
      <UploadFile
        label="Upload"
        fileIcon={xlsUploadIcon}
        accept={xlsxAccept}
        loading={uploadSecondPagesFileLoading}
        onUpload={onUploadSecondPagesFile}
      />
    </div>
  );
}
