import React from 'react';
import cn from 'classnames';
import closeIcon from '../../assets/images/close-icon.png';
import styles from './Notification.module.scss';

const variants = {
  success: 'success',
  error: 'error',
};

type Props = {
  text: string;
  variant?: typeof variants[keyof typeof variants];
  closeToast?: () => void;
}

export default function Notification({text, variant = variants.error, closeToast}: Props) {
  function closeNotification() {
    if (closeToast) {
      closeToast();
    }
  }

  return (
    <div className={cn(styles.container, variant === variants.error && styles.error, variant=== variants.success && styles.success)}>
      <div>{text}</div>
      <img onClick={closeNotification} src={closeIcon} alt="close icon" />
    </div>
  );
}
