import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { downloadFile, getUrl } from '../utils';
import { RootState } from './index';

export type Qc1 = {
  respondents: string[];
  qced: string[];
};

export type Qc1State = {
  uploadQc1FileLoading: boolean;
  selectedQc1: Qc1 | null;
};

const initialState: Qc1State = {
  uploadQc1FileLoading: false,
  selectedQc1: null,
};

export const fetchProjectQc1 = createAsyncThunk<Qc1, string>(
  'project/qc1',
  async (projectId, thunkAPI) => {
    try {
      const response = await axios.get(
        getUrl(`/test/projects/${projectId}/qc1`),
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

type GenerateQc1TranscribePayload = {
  project_id: string;
  new_tagged: boolean;
};
export const generateProjectQc1Transcribe = createAsyncThunk<
  string,
  GenerateQc1TranscribePayload
>(
  'project/qc1/generate_transcribe',
  async ({ project_id, new_tagged }, thunkAPI) => {
    try {
      const response = await axios.post(
        getUrl(`/test/projects/${project_id}/qc1/generate`),
        {
          model_name: 'tiny.en',
          use_question_as_prompt: true,
        },
        { params: { new_tagged } },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

type DeleteProjectQc1TranscribeSessionPayload = {
  project_id: string;
  ordinal_session_id: string;
};
export const deleteProjectQc1Respondent = createAsyncThunk<
  string,
  DeleteProjectQc1TranscribeSessionPayload
>(
  'project/qc1/delete_respondent',
  async ({ project_id, ordinal_session_id }, thunkAPI) => {
    try {
      const response = await axios.delete(
        getUrl(`/test/projects/${project_id}/qc1/delete_session`),
        { params: { ordinal_session_id } },
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

type UploadProjectQc1FilePayload = {
  projectId: string;
  file: File;
};
export const uploadProjectQc1File = createAsyncThunk<
  string,
  UploadProjectQc1FilePayload
>('project/qc1/upload_file', async ({ projectId, file }, thunkAPI) => {
  try {
    const body = new FormData();
    body.append('file', file);
    const response = await axios.post(
      getUrl(`/test/projects/${projectId}/qc1/upload`),
      body,
    );
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const downloadQc1File = createAsyncThunk(
  'project/qc1/downloadFile',
  async (projectId: string, thunkAPI) => {
    try {
      const response = await axios.get(
        getUrl(`/test/projects/${projectId}/qc1/download_file`),
        { responseType: 'blob' },
      );
      downloadFile(response.data, response.headers.file_name);
      return response.headers.file_name;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const qc1Slice = createSlice({
  name: 'qc1',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchProjectQc1.fulfilled,
      (state: Qc1State, action: PayloadAction<Qc1>) => {
        state.selectedQc1 = action.payload;
      },
    );

    builder.addCase(uploadProjectQc1File.pending, (state: Qc1State) => {
      state.uploadQc1FileLoading = true;
    });

    builder.addMatcher(
      (action) => {
        return (
          action.type === uploadProjectQc1File.fulfilled.type ||
          action.type === uploadProjectQc1File.rejected.type
        );
      },
      (state: Qc1State) => {
        state.uploadQc1FileLoading = false;
      },
    );
  },
});

const qc1StateSelector = (state: RootState) => state.qc1Reducer;

export const qcedCountSelector = createSelector(
  qc1StateSelector,
  (state: Qc1State) => state.selectedQc1?.qced?.length || 0,
);

export const respondentsCountSelector = createSelector(
  qc1StateSelector,
  (state: Qc1State) => state.selectedQc1?.respondents?.length || 0,
);

export const uploadQc1FileLoadingSelector = createSelector(
  qc1StateSelector,
  (state: Qc1State) => state.uploadQc1FileLoading,
);
