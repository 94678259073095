import React, { ChangeEventHandler } from "react";

import styles from "./TextInput.module.scss";

type Props = {
  id: string;
  type?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
};

export default function TextInput({
  id,
  type = "text",
  label,
  name,
  value,
  onChange,
  placeholder,
  className,
}: Props) {
  return (
    <div className={`${styles.container} ${className || ""}`}>
      {!!label && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}
