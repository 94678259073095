import React, { useCallback, MouseEvent } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import styles from "./SignIn.module.scss";
import logo from "../../../assets/images/logo.png";
import signInIcon from "../../../assets/images/sign-in-icon.png";
import TextInput from "../../../components/TextInput/TextInput";
import Button from "../../../components/Button/Button";
import { login } from "../../../store/auth";
import { useAppDispatch } from "../../../store/hooks";

type SignInFormData = {
  username: string;
  password: string;
};

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleSubmit, ...formik } = useFormik<SignInFormData>({
    initialValues: {
      password: "",
      username: "",
    },
    onSubmit: ({ username, password }) => {
      if (username && password) {
        dispatch(login({ username, password })).then(() => {
          navigate("/projects");
        });
      }
    },
  });

  const onSubmitClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit],
  );

  return (
    <div className={styles.container}>
      <div className={styles.backgroundImage} />
      <div className={styles.formContainer}>
        <img src={logo} alt="logo" />
        <div className={styles.formContent}>
          <div className={styles.header}>
            <h1>Sign In</h1>
            <img src={signInIcon} alt="" />
          </div>
          <form>
            <TextInput
              id="username"
              name="username"
              label="E-mail"
              value={formik.values.username}
              onChange={formik.handleChange}
            />
            <TextInput
              id="password"
              type="password"
              label="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <div className={styles.buttonContainer}>
              <Button label="Sign in" onClick={onSubmitClick} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
