import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Fuse from 'fuse.js';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import styles from './Projects.module.scss';
import { Project } from '../../types';
import Header from '../../components/Header/Header';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import TextInput from '../../components/TextInput/TextInput';
import plusIcon from '../../assets/images/plus-icon.png';
import AddProjectForm from '../../components/AddProjectForm/AddProjectForm';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  createProject,
  CreateProjectPayload,
  fetchAllProjects,
  projectsSelector,
  updateProject,
} from '../../store/projects';
import { showCommonError, showToast } from '../../utils';

export default function Projects() {
  const dispatch = useAppDispatch();
  const projects = useAppSelector<Project[] | null>(projectsSelector);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>(
    projects || [],
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const fuse = useMemo(() => {
    return new Fuse(projects || ([] as Project[]), {
      keys: ['project_name', 'brand_name', 'company_name'],
    });
  }, [projects]);

  const filterProjects = (query: string) => {
    if (!projects) {
      setFilteredProjects([]);
      return;
    }
    if (!query) {
      setFilteredProjects(projects || []);
      return;
    }
    const result = fuse.search(query).map(({ item }) => item);
    setFilteredProjects(result);
  };

  useEffect(() => {
    dispatch(fetchAllProjects());
  }, [dispatch]);

  useEffect(() => {
    if (!search && !!projects?.length) {
      setFilteredProjects(projects);
    }
  }, [projects, search]);

  function openDrawer() {
    setIsDrawerOpen(true);
  }

  function closeDrawer() {
    setIsDrawerOpen(false);
  }

  function onSearchChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setSearch(value);
    filterProjects(value);
  }

  const onCreateProject = useCallback(
    async (payload: CreateProjectPayload) => {
      try {
        const result = await dispatch(createProject(payload));
        if (result.type === createProject.fulfilled.type) {
          showToast('success', 'Project details saved successfully');
        } else if (result.type === createProject.rejected.type) {
          showCommonError();
        }
      } finally {
        closeDrawer();
      }
    },
    [dispatch],
  );

  const onUpdateProject = useCallback(
    async (payload: Project) => {
      try {
        const result = await dispatch(updateProject(payload));
        if (result.type === updateProject.fulfilled.type) {
          showToast('success', 'Project details saved successfully');
        } else if (result.type === updateProject.rejected.type) {
          showCommonError();
        }
      } finally {
        closeDrawer();
      }
    },
    [dispatch],
  );

  const addProject = useCallback(() => {
    setSelectedProject(null);
    openDrawer();
  }, []);

  const editProject = useCallback((project: Project) => {
    setSelectedProject(project);
    openDrawer();
  }, []);

  return (
    <div className={styles.container}>
      <Header>
        <div className={styles.inputWrapper}>
          <TextInput
            id="search"
            value={search}
            onChange={onSearchChange}
            placeholder="Search"
            className={styles.search}
          />
        </div>
      </Header>
      <h1>Select project</h1>
      <div className={styles.content}>
        <div className={styles.addButton} onClick={addProject}>
          <img src={plusIcon} alt="plus-icon" />
          <span>Add Project</span>
        </div>
        {filteredProjects?.map((project) => (
          <ProjectCard
            key={project.id}
            project={project}
            onEditProject={editProject}
          />
        ))}
      </div>
      <Drawer
        open={isDrawerOpen}
        direction="right"
        onClose={closeDrawer}
        size={550}>
        <AddProjectForm
          project={selectedProject}
          onCancel={closeDrawer}
          onCreateProject={onCreateProject}
          onUpdateProject={onUpdateProject}
        />
      </Drawer>
    </div>
  );
}
