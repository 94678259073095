import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

import SignIn from "./pages/Auth/SignIn/SignIn";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Projects from "./pages/Projects/Projects";
import Project from "./pages/Project/Project";

export const router = createBrowserRouter([
  { path: "/sign-in", element: <SignIn /> },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "projects",
        element: <Projects />,
      },
      { path: "projects/:id", element: <Project /> },
    ],
  },
  { path: "*", element: <Navigate to="/projects" replace /> },
]);
