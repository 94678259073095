import React, { useCallback } from 'react';
import styles from './Rules.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  deckLoadingSelector,
  ruleLoadingSelector,
  uploadProjectDeck,
  uploadProjectRule,
} from '../../../store/projects';
import UploadFile from '../../../components/UploadFile/UploadFile';
import xlsIcon from '../../../assets/images/xls-file.png';
import pptIcon from '../../../assets/images/ppt-file.png';
import { getIsError422, showToast } from '../../../utils';
import { AxiosError } from 'axios';

type Props = {
  projectId: string;
};

const xlsxAccept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

const pptAccept = {
  'application/vnd.ms-powerpoint': ['.pptx'],
};

export default function Rules({ projectId }: Props) {
  const dispatch = useAppDispatch();
  const ruleLoading = useAppSelector<boolean>(ruleLoadingSelector);
  const deckLoading = useAppSelector<boolean>(deckLoadingSelector);

  const onUploadRule = useCallback(
    async (rule: File) => {
      const result = await dispatch(uploadProjectRule({ projectId, rule }));
      if (result.type === uploadProjectRule.fulfilled.type) {
        showToast('success', 'Rule successfully saved!');
      } else if (
        result.type === uploadProjectRule.rejected.type &&
        !getIsError422(result.payload as AxiosError)
      ) {
        showToast('error', 'Failed to added rule');
      }
    },
    [dispatch, projectId],
  );

  const onUploadDeck = useCallback(
    async (deck: File) => {
      const result = await dispatch(uploadProjectDeck({ projectId, deck }));
      if (result.type === uploadProjectDeck.fulfilled.type) {
        showToast('success', 'Deck successfully saved!');
      } else if (
        result.type === uploadProjectDeck.rejected.type &&
        !getIsError422(result.payload as AxiosError)
      ) {
        showToast('error', 'Failed to added deck');
      }
    },
    [dispatch, projectId],
  );

  return (
    <div className={styles.container}>
      <UploadFile
        fileIcon={xlsIcon}
        label="Upload Rules"
        accept={xlsxAccept}
        onUpload={onUploadRule}
        loading={ruleLoading}
      />
      <UploadFile
        fileIcon={pptIcon}
        label="Upload Deck"
        accept={pptAccept}
        onUpload={onUploadDeck}
        loading={deckLoading}
      />
    </div>
  );
}
