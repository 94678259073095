import React, { useCallback, useEffect, useMemo } from 'react';

import styles from './ClusterTraining.module.scss';
import UploadFile from '../../../components/UploadFile/UploadFile';
import xlsIcon from '../../../assets/images/xls-file.png';
import Button from '../../../components/Button/Button';
import CohortsSelector from '../../../components/CohortsSelector/CohortsSelector';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  cohortsSelector,
  downloadQc2ConsolidationFile,
  getQc2BmaConsolidation,
  uploadQc2ConsolidationFile,
  uploadQc2ConsolidationFileLoadingSelector,
} from '../../../store/qc2';
import { trainingConsolidation } from '../../../store/training';
import { getIsError422, showCommonError, showToast } from '../../../utils';
import { AxiosError } from 'axios';

const xlsxAccept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

type Props = {
  projectId: string;
};

export default function ClusterTraining({ projectId }: Props) {
  const dispatch = useAppDispatch();
  const cohorts = useAppSelector(cohortsSelector);
  const uploadLoading = useAppSelector(
    uploadQc2ConsolidationFileLoadingSelector,
  );
  useEffect(() => {
    dispatch(getQc2BmaConsolidation(projectId));
  }, [dispatch, projectId]);
  const cohortRefs = useMemo(() => {
    return cohorts.map(() => React.createRef<{ getChecked: () => string[] }>());
  }, [cohorts]);

  const onUpload = useCallback(
    async (file: File) => {
      const result = await dispatch(
        uploadQc2ConsolidationFile({ file, projectId }),
      );
      if (result.type === uploadQc2ConsolidationFile.fulfilled.type) {
        showToast('success', 'File uploaded successfully');
      } else if (
        result.type === uploadQc2ConsolidationFile.rejected.type &&
        !getIsError422(result.payload as AxiosError)
      ) {
        showCommonError();
      }
    },
    [dispatch, projectId],
  );

  const onTrainModel = useCallback(async () => {
    const result = await dispatch(trainingConsolidation());
    if (result.type === trainingConsolidation.fulfilled.type) {
      showToast('success', 'Training process has started successfully');
    } else if (result.type === trainingConsolidation.rejected.type) {
      showCommonError();
    }
  }, [dispatch]);

  const onDownloadCohorts = useCallback(async () => {
    const consolidation_sections = cohortRefs
      .map((ref) => {
        return ref.current?.getChecked();
      })
      .reduce((acc, value) => {
        const checkedSections = (value || [])
          ?.map((item) => item.split(';'))
          .filter((arr) => arr.length === 3);
        return acc?.concat(checkedSections);
      }, [] as string[][]) as [string, string, string][];
    if (consolidation_sections.length) {
      showToast('success', 'Download has started successfully');
      const result = await dispatch(
        downloadQc2ConsolidationFile({ projectId, consolidation_sections }),
      );
      if (result.type === downloadQc2ConsolidationFile.rejected.type) {
        showCommonError();
      }
    }
  }, [dispatch, projectId, cohortRefs]);

  return (
    <div className={styles.container}>
      <div className={styles.sideBar}>
        <div className={styles.cohorts}>
          {cohorts.map((cohort, index) => (
            <CohortsSelector
              key={index.toString()}
              cohort={cohort}
              ref={cohortRefs[index]}
            />
          ))}
        </div>
        <div className={styles.actions}>
          <Button label="Donwload" onClick={onDownloadCohorts} />
        </div>
      </div>
      <div className={styles.content}>
        <div>
          <UploadFile
            label="Upload QC file"
            fileIcon={xlsIcon}
            accept={xlsxAccept}
            loading={uploadLoading}
            onUpload={onUpload}
          />
          <Button label="Train model" onClick={onTrainModel} disabled />
        </div>
      </div>
    </div>
  );
}
