import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { downloadFile, getUrl } from '../utils';
import { RootState } from './index';

export const fetchQc3Consolidation = createAsyncThunk(
  'project/qc3/generate',
  async (projectId: string, thunkAPI) => {
    try {
      const response = await axios.get(
        getUrl(`/test/projects/${projectId}/qc3/generate`),
        { responseType: 'blob' },
      );
      downloadFile(response.data, response.headers.file_name);
      return response.headers.file_name;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

type UploadQc3FilePayload = {
  file: File;
  projectId: string;
};
export const uploadQc3File = createAsyncThunk<string, UploadQc3FilePayload>(
  'project/qc3/upload',
  async ({ file, projectId }, thunkAPI) => {
    try {
      const body = new FormData();
      body.append('file', file);
      const response = await axios.post(
        getUrl(`/test/projects/${projectId}/qc3/upload`),
        body,
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export type Qc3State = {
  uploadQc3FileLoading: boolean;
};

const initialState: Qc3State = {
  uploadQc3FileLoading: false,
};

export const qc3Slice = createSlice({
  name: 'qc3',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadQc3File.pending, (state: Qc3State) => {
      state.uploadQc3FileLoading = true;
    });

    builder.addMatcher(
      (action) => {
        return (
          action.type === uploadQc3File.fulfilled.type ||
          action.type === uploadQc3File.rejected.type
        );
      },
      (state: Qc3State) => {
        state.uploadQc3FileLoading = false;
      },
    );
  },
});

const qc3StateSelector = (state: RootState) => state.qc3Reducer;

export const uploadQc3FileLoadingSelector = createSelector(
  qc3StateSelector,
  (state: Qc3State) => state.uploadQc3FileLoading,
);
