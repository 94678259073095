import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import styles from './TreeNode.module.scss';
import checkedIcon from '../../assets/images/checkbox-checked-icon.png';
import indeterminateIcon from '../../assets/images/checkbox-indeterminate-icon.png';
import arrowDown from '../../assets/images/arrow-down-icon.png';
import arrowLeft from '../../assets/images/arrow-left-icon.png';

type CheckboxValue = boolean | "indeterminate";
type TreeNodeCheckboxProps = {
  checked: CheckboxValue
  onChange: () => void;
  label: string;
}

function TreeNodeCheckbox({checked, onChange, label}: TreeNodeCheckboxProps) {
  return (
    <div className={styles.container}>
      <div onClick={onChange} className={`${styles.checkbox} ${!!checked ? styles.checked : ''}`}>
        {checked === true && (<img src={checkedIcon} alt="checked icon" />)}
        {checked === 'indeterminate' && (<img src={indeterminateIcon} alt="indeterminate icon" />)}
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
}

export type Node<T = string> = {
  label: string;
  value: T;
  children?: Node[] | null | undefined;
}

type Props<T = string> = {
  rootNode: Node<T>;
  level?: number;
  checked: T[];
  onCheck: Dispatch<SetStateAction<T[]>>;
  expended: T[];
  onExpand: Dispatch<SetStateAction<T[]>>;
  parentValues?: T[];
}

function getNodeChildValues(node: Node) {
  const values: string[] = [];
  values.push(node.value);
  const children = node.children || [];
  children.forEach(child => {
    const childValues = getNodeChildValues(child);
    values.push(...childValues);
  });
  return values;
}

export function CheckboxTree({rootNode, checked, onCheck, expended, onExpand, level = 0, parentValues = []}: Props) {
  const value = rootNode.value;
  const hasChildren = useMemo(() => rootNode.children, [rootNode]);
  const isExpanded = useMemo(() => expended.includes(value), [expended, value]);
  const isChecked = useMemo(() => checked.includes(value), [checked, value]);
  const childValues = useMemo(() => getNodeChildValues(rootNode), [rootNode]);
  const levelParentValues = useMemo(() => [...parentValues, value], [value, parentValues]);
  const checkboxValue = useMemo<CheckboxValue>(() => {
    if (isChecked) {
      return true;
    }
    return checked.some(item => childValues.includes(item)) ? 'indeterminate' : false;
  }, [isChecked, childValues, checked]);

  useEffect(() => {
    if (hasChildren && !isChecked) {
      const rootLevelChildValues = (rootNode.children || []).map(({value}) => value);
      if (rootLevelChildValues.every(item => checked.includes(item))) {
        onCheck(prev => [...prev, value]);
      }
    }
  }, [hasChildren, isChecked, checked, rootNode, onCheck, value]);

  const onArrowPress = useCallback(() => {
    if (!isExpanded) {
      onExpand(prev => [...prev, value]);
    } else {
      onExpand(prev => prev.filter(item => item !== value));
    }
  }, [isExpanded, onExpand, value]);

  const onCheckboxPress = useCallback(() => {
    if (isChecked) {
      onCheck(prev => prev.filter(item => ![...childValues, ...parentValues].includes(item)));
    } else {
      onCheck(prev => [...prev, ...childValues]);
    }
  }, [isChecked, onCheck, childValues, parentValues]);

  return (
    <div className={styles.checkboxTreeContainer} style={{marginLeft: level * 20}}>
      <div className={styles.root}>
        {hasChildren && <div className={styles.arrow} onClick={onArrowPress}><img src={isExpanded ? arrowDown : arrowLeft} alt="arrow icon" /></div>}
        <div className={!hasChildren ? styles.lastChild : ''}>
          <TreeNodeCheckbox checked={checkboxValue} onChange={onCheckboxPress} label={rootNode.label} />
        </div>
      </div>
      {hasChildren && isExpanded && rootNode.children?.map(node => (
        <CheckboxTree
          key={node.value}
          rootNode={node}
          level={level + 1}
          checked={checked}
          expended={expended}
          onCheck={onCheck}
          onExpand={onExpand}
          parentValues={levelParentValues}
        />
      ))}
    </div>
  );
}
