import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  fetchProjectById,
  projectsSlice,
  selectedProjectSelector,
  updateProject,
} from '../../store/projects';
import styles from './Project.module.scss';
import Header from '../../components/Header/Header';
import ProjectStatusBar from '../../components/ProjectStatusBar/ProjectStatusBar';
import { ProjectStatus } from '../../types';
import { BackButton, ForwardButton } from '../../components/Button/Button';
import Rules from './Rules/Rules';
import QC1 from './QC1/QC1';
import QC2 from './QC2/QC2';
import ClusterTraining from './ClusterTraining/ClusterTraining';
import DeleteRespondentForm from '../../components/DeleteRespondentForm/DeleteRespondentForm';
import { deleteProjectQc1Respondent, fetchProjectQc1 } from '../../store/qc1';
import QC3 from './QC3/QC3';
import SecondPages from './SecondPages/SecondPages';
import DataRoom from './DataRoom/DataRoom';

const STATUS_ORDER = [
  ProjectStatus.Rules,
  ProjectStatus.QC1,
  ProjectStatus.QC2,
  ProjectStatus.ClusterTraining,
  ProjectStatus.QC3,
  ProjectStatus.SecondPages,
  ProjectStatus.DataRoom,
];

export default function Project() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const project = useAppSelector(selectedProjectSelector);

  useEffect(() => {
    if (id) {
      dispatch(fetchProjectById(id));
    }
    return () => {
      dispatch(projectsSlice.actions.resetSelectedProject());
    };
  }, [id, dispatch]);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const status = useMemo(() => project?.project_status, [project]);
  const currentIndex = useMemo<number>(() => {
    return STATUS_ORDER.findIndex((s) => s === status);
  }, [status]);
  const isFirst = useMemo<boolean>(() => {
    return currentIndex === 0;
  }, [currentIndex]);
  const isLast = useMemo<boolean>(() => {
    return currentIndex === STATUS_ORDER.length - 1;
  }, [currentIndex]);

  const onDeleteRespondent = useCallback(
    async (respondent: string) => {
      await dispatch(
        deleteProjectQc1Respondent({
          project_id: id!,
          ordinal_session_id: respondent,
        }),
      ).then(() => {
        closeDrawer();
        dispatch(fetchProjectQc1(id!));
      });
    },
    [id, dispatch],
  );

  function renderContent() {
    if (project) {
      switch (status) {
        case ProjectStatus.Rules: {
          return <Rules projectId={project.id} />;
        }
        case ProjectStatus.QC1: {
          return (
            id && <QC1 projectId={id} onDeleteRespondentClick={openDrawer} />
          );
        }
        case ProjectStatus.QC2: {
          return id && <QC2 projectId={id} />;
        }
        case ProjectStatus.ClusterTraining: {
          return id && <ClusterTraining projectId={id} />;
        }
        case ProjectStatus.QC3: {
          return id && <QC3 projectId={id} />;
        }
        case ProjectStatus.SecondPages: {
          return id && <SecondPages projectId={id} />;
        }
        case ProjectStatus.DataRoom: {
          return id && <DataRoom projectId={id} />;
        }
        default: {
          return null;
        }
      }
    }
    return null;
  }

  function openDrawer() {
    setIsDrawerOpen(true);
  }

  function closeDrawer() {
    setIsDrawerOpen(false);
  }

  const onChangeProjectStatus = useCallback(
    (step: number) => {
      return () => {
        let nextIndex = currentIndex + step;
        let status = STATUS_ORDER[nextIndex];
        while (
          (status === ProjectStatus.QC2 && project?.using_bma === false) ||
          status === ProjectStatus.BmaTraining
        ) {
          nextIndex += step;
          status = STATUS_ORDER[nextIndex];
        }
        if (project && status) {
          dispatch(updateProject({ ...project, project_status: status }));
        }
      };
    },
    [dispatch, project, currentIndex],
  );

  return (
    <div className={styles.container}>
      <Header />
      {project && status && (
        <>
          <h1>{project.project_name}</h1>
          <div className={styles.content}>
            <ProjectStatusBar
              currentStatus={status}
              using_bma={project.using_bma}
            />
            <div className={styles.statusData}>{renderContent()}</div>
            <div className={styles.actions}>
              {!isFirst && (
                <div>
                  <BackButton onClick={onChangeProjectStatus(-1)} />
                </div>
              )}
              {!isLast && (
                <div>
                  <ForwardButton onClick={onChangeProjectStatus(1)} />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <Drawer
        open={isDrawerOpen}
        direction="right"
        onClose={closeDrawer}
        size={550}>
        <DeleteRespondentForm
          onCancel={closeDrawer}
          onDeleteRespondent={onDeleteRespondent}
        />
      </Drawer>
    </div>
  );
}
