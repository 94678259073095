import React, { useCallback } from 'react';
import styles from './DataRoom.module.scss';
import xlsDownloadIcon from '../../../assets/images/xls-download.png';
import pptDownloadIcon from '../../../assets/images/ppt-download.png';
import DownloadFile from '../../../components/DownloadFile/DownloadFile';
import { useAppDispatch } from '../../../store/hooks';
import {
  downloadDataRoomSecondPages,
  downloadDataRootPowerpoint,
} from '../../../store/dataRoom';
import { showToast } from '../../../utils';

type Props = {
  projectId: string;
};

export default function DataRoom({ projectId }: Props) {
  const dispatch = useAppDispatch();

  const onDownloadSecondPagesFile = useCallback(() => {
    showToast('success', '2nd page download has started successfully');
    dispatch(downloadDataRoomSecondPages(projectId));
  }, [dispatch, projectId]);

  const onDownloadDeckAndStat = useCallback(() => {
    showToast('success', 'Deck download has started successfully');
    dispatch(downloadDataRootPowerpoint(projectId));
  }, [dispatch, projectId]);

  return (
    <div className={styles.container}>
      <DownloadFile
        label="Final 2nd pages"
        fileIcon={xlsDownloadIcon}
        onDownload={onDownloadSecondPagesFile}
      />
      <DownloadFile
        label="Deck and stat"
        fileIcon={pptDownloadIcon}
        onDownload={onDownloadDeckAndStat}
      />
    </div>
  );
}
