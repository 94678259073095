import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './ProjectStatusBar.module.scss';
import { ProjectStatus } from '../../types';
import checkmarkIcon from '../../assets/images/checkmark-icon.png';

type Props = {
  using_bma?: boolean;
  currentStatus: ProjectStatus;
};

const STATUS_ORDER = [
  ProjectStatus.Rules,
  ProjectStatus.QC1,
  ProjectStatus.BmaTraining,
  ProjectStatus.QC2,
  ProjectStatus.ClusterTraining,
  ProjectStatus.QC3,
  ProjectStatus.SecondPages,
  ProjectStatus.DataRoom,
];

export default function ProjectStatusBar({ currentStatus, using_bma }: Props) {
  const bmaIndex = useMemo(() => {
    return STATUS_ORDER.findIndex((status) => status === ProjectStatus.QC2);
  }, []);
  const bmaTrainingIndex = useMemo(
    () =>
      STATUS_ORDER.findIndex((status) => status === ProjectStatus.BmaTraining),
    [],
  );
  const currentStatusIndex = useMemo(() => {
    return STATUS_ORDER.findIndex((status) => status === currentStatus)!;
  }, [currentStatus]);
  return (
    <div className={styles.container}>
      {STATUS_ORDER.map((status, index) => {
        const isLast = index === STATUS_ORDER.length - 1;
        return (
          <div key={status} className={styles.statusItem}>
            <label
              className={cn({
                [styles.finished]:
                  index <= currentStatusIndex &&
                  !(!using_bma && index === bmaIndex) &&
                  index !== bmaTrainingIndex,
                [styles.current]: index === currentStatusIndex,
              })}>
              {status}
            </label>
            <div>
              <div
                className={cn({
                  [styles.done]:
                    index < currentStatusIndex &&
                    !(!using_bma && index === bmaIndex) &&
                    index !== bmaTrainingIndex,
                  [styles.pending]: index === currentStatusIndex,
                })}>
                {index < currentStatusIndex &&
                  !(!using_bma && index === bmaIndex) &&
                  index !== bmaTrainingIndex && (
                    <img src={checkmarkIcon} alt="checkmark icon" />
                  )}
                {index === currentStatusIndex && <div />}
              </div>
              {!isLast && (
                <div
                  className={cn({ [styles.done]: index <= currentStatusIndex })}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
