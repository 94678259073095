import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { downloadFile, getUrl } from '../utils';

export const downloadDataRoomSecondPages = createAsyncThunk(
  'dataRoom/secondPages/download',
  async (projectId: string, thunkAPI) => {
    try {
      const response = await axios.get(
        getUrl(`/test/projects/${projectId}/data_room/second_pages`),
        { responseType: 'blob' },
      );
      downloadFile(response.data, response.headers.file_name);
      return response.headers.file_name;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const downloadDataRootPowerpoint = createAsyncThunk(
  'dataRoom/powerpoint/download',
  async (projectId: string, thunkAPI) => {
    try {
      const response = await axios.get(
        getUrl(`/test/projects/${projectId}/data_room/powerpoint`),
        { responseType: 'blob' },
      );
      downloadFile(response.data, response.headers.file_name);
      return response.headers.file_name;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
export const dataRoomSlice = createSlice({
  name: 'dataRoom',
  initialState: {},
  reducers: {},
});
