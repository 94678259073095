import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Header.module.scss";
import logo from "../../assets/images/logo-small.png";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchUser, userSelector } from "../../store/auth";

type Props = PropsWithChildren;
function Header({ children }: Props) {
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  const navigateToProjects = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  return (
    <div className={styles.container}>
      <img src={logo} alt="logo" onClick={navigateToProjects} />
      <div className={styles.content}>{children}</div>
      {!!user && (
        <>
          <span className={styles.username}>{user?.username}</span>
          <div className={styles.avatar}>{user?.username.at(0)}</div>
        </>
      )}
    </div>
  );
}

export default React.memo(Header);
