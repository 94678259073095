import { toast } from 'react-toastify';
import Notification from './components/Notification/Notification';
import { API_URL } from './constants';
import { AxiosError } from 'axios';

type ToastType = 'success' | 'error';
export function showToast(type: ToastType, message: string) {
  toast(<Notification variant={type} text={message} />);
}

export function showCommonError() {
  showToast('error', 'Something went wrong');
}

export function getUrl(relativeUrl: string) {
  return `${API_URL}${relativeUrl}`;
}

export function downloadFile(data: Blob, filename: string) {
  const href = URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function getIsError422(error?: AxiosError) {
  return error?.response?.status === 422;
}
