import React, { MouseEventHandler } from 'react';

import cn from 'classnames';
import styles from './Button.module.scss';

import arrowBackIcon from '../../assets/images/arrow-back.png';
import arrowForwardIcon from '../../assets/images/arrow-forward.png';

type Props = {
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  isOutlined?: boolean;
  isText?: boolean;
  disabled?: boolean;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
};

export default function Button({
  label,
  onClick,
  isOutlined,
  isText,
  disabled,
  className,
  leftIcon,
  rightIcon,
}: Props) {
  return (
    <button
      className={cn(
        {
          [styles.container]: true,
          [styles.outlined]: isOutlined,
          [styles.text]: isText,
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled}>
      {!!leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
      {label}
      {!!rightIcon && <div className={styles.rightIcon}>{rightIcon}</div>}
    </button>
  );
}

export const BackButton = ({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => (
  <Button
    isText
    label="Back"
    onClick={onClick}
    leftIcon={<img src={arrowBackIcon} alt="back icon" />}
  />
);

export const ForwardButton = ({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => (
  <Button
    isOutlined
    label="Forward"
    onClick={onClick}
    rightIcon={<img src={arrowForwardIcon} alt="forward icon" />}
  />
);
