import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';

import { store } from './store';
import { authSlice, TOKEN_STORE_KEY } from './store/auth';
import { router } from './router';
import { showToast } from './utils';

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        autoClose={false}
        draggable={false}
        position="top-center"
        style={{
          width: 'auto',
          padding: 0,
        }}
        toastStyle={{
          padding: 0,
          borderRadius: 12,
        }}
      />
    </Provider>
  );
}

export default App;

const UNAUTHORIZED = 401;
const UNPROVESSABLE_CONTENT = 422;
const { dispatch } = store;
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    try {
      const status = error?.response?.status;
      if (status === UNAUTHORIZED) {
        localStorage.removeItem(TOKEN_STORE_KEY);
        dispatch(authSlice.actions.setToken(null));
      }
      if (status === UNPROVESSABLE_CONTENT) {
        if (error?.request?.responseType === 'blob') {
          const text = await error?.response?.data?.text();
          const message = JSON.parse(text)?.detail;
          if (message) {
            showToast('error', message);
          }
        } else {
          const message = error?.response?.data?.detail;
          if (message) {
            showToast('error', message);
          }
        }
      }
      return Promise.reject(error);
    } catch (e) {}
  },
);
