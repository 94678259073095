import React from "react";

import styles from "./Badge.module.scss";

type Props = {
  isCompleted?: boolean;
};
export default function Badge({ isCompleted = false }: Props) {
  return (
    <div
      className={`${styles.container} ${
        isCompleted ? styles.completed : styles.active
      }`}
    >
      {isCompleted ? "Completed" : "Active"}
    </div>
  );
}
