import React, { useCallback, MouseEvent } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import styles from './AddProjectForm.module.scss';
import Button from '../Button/Button';
import TextInput from '../TextInput/TextInput';
import Checkbox from '../Checkbox/Checkbox';
import { CreateProjectPayload } from '../../store/projects';
import { Project } from '../../types';

type Props = {
  project: Project | null;
  onCancel: () => void;
  onUpdateProject: (payload: Project) => Promise<void>;
  onCreateProject: (payload: CreateProjectPayload) => Promise<void>;
};

type Values = {
  project_name: string;
  brand_name: string;
  company_name: string;
  project_size: string;
  using_bma: boolean;
  survey_id: string;
};
export default function AddProjectForm({
  project,
  onUpdateProject,
  onCreateProject,
  onCancel,
}: Props) {
  const { handleSubmit, errors, ...formik } = useFormik<Values>({
    initialValues: {
      project_name: project?.project_name || '',
      brand_name: project?.brand_name || '',
      company_name: project?.company_name || '',
      project_size: project?.project_size?.toString() || '',
      using_bma: project?.using_bma || false,
      survey_id: project?.survey_id || '',
    },
    onSubmit: async ({ project_size, survey_id, ...rest }, { resetForm }) => {
      const surveyId = survey_id?.split('/')?.at(-1) || '';
      if (project) {
        await onUpdateProject({
          ...project,
          ...rest,
          project_size: +project_size,
          survey_id: surveyId,
        });
      } else {
        await onCreateProject({
          ...rest,
          project_size: +project_size,
          survey_id: surveyId,
        });
      }
      resetForm();
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      project_name: yup.string().required('Project name'),
      brand_name: yup.string().required('Brand name'),
      company_name: yup.string().required('Company name'),
      project_size: yup.string().required('Project size'),
      using_bma: yup.boolean(),
      survey_id: yup.string().required('Survey'),
    }),
    validateOnMount: true,
  });

  const onSaveProjectClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!Object.keys(errors).length) {
        handleSubmit();
      } else {
        const errorFields = Object.values(errors);
        if (errorFields.length) {
          window.alert(
            `${errorFields.join(', ')} field${
              errorFields.length > 1 ? 's are' : ' is'
            } required`,
          );
        }
      }
    },
    [handleSubmit, errors],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Add Project</h1>
      </div>
      <div className={styles.content}>
        <TextInput
          id="project_name"
          name="project_name"
          value={formik.values.project_name}
          onChange={formik.handleChange}
          label="Project name"
        />
        <TextInput
          id="brand_name"
          name="brand_name"
          value={formik.values.brand_name}
          onChange={formik.handleChange}
          label="Brand"
        />
        <TextInput
          id="company_name"
          name="company_name"
          value={formik.values.company_name}
          onChange={formik.handleChange}
          label="Company"
        />
        <TextInput
          id="project_size"
          name="project_size"
          value={formik.values.project_size}
          onChange={formik.handleChange}
          label="Completer goal"
        />
        <Checkbox
          id="using_bma"
          name="using_bma"
          label="Project has BMA"
          checked={formik.values.using_bma}
          onChange={formik.handleChange}
        />
        <TextInput
          id="survey_id"
          name="survey_id"
          value={formik.values.survey_id}
          onChange={formik.handleChange}
          label="Phonic link"
        />
      </div>
      <div className={styles.footer}>
        <div>
          <Button label="Cancel" onClick={onCancel} isOutlined />
        </div>
        <div>
          <Button label="Save" onClick={onSaveProjectClick} />
        </div>
      </div>
    </div>
  );
}
